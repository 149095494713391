import _ from "lodash";
import api from '../../api/travels.js';

const namespaced = true


const state = {
    travels: [],
    travelsTemp: [],
    loading: false,
    productNames: [],
    unitsPlane: [],
    permitteeName: [],
    unitsId: [],
    customers: [],
    selectedFilters: {
        product_name: [],
        licence_plate: [],
        permittee: [],
        unit_id: [],
        customers: []
    },
    flagTravels: false
};

const mutations = {
    MUTATIONS_SET_TRAVELS(state, value) {
        state.travels = value;
        state.travelsTemp = value;
        state.productNames = _.chain(state.travels).flatMap(travel => travel.lines).map('product_name').uniq().value();
        state.unitsPlane = _.chain(state.travels).flatMap(travel => travel.unit).map('licence_plate').uniq().value();
        state.permitteeName = _.chain(state.travels).flatMap(travel => travel.permittee).map('name').uniq().value();
        state.unitsId = _.chain(state.travels).flatMap(travel => travel.unit).map('id').uniq().value();
        state.customers = _.chain(state.travels).flatMap(travel => travel.lines).map('source_customer').uniq().value();
        _.forEach(state.travels, travel => {
            const filteredLines = _.filter(travel.lines, line => line.product_name != 'Cargas Diversas');
            const products = _.map(filteredLines, 'product_name');
            const uniqueProducts = _.uniq(products);
            travel.flag_product_different = uniqueProducts.length > 1;
        });
        
        state.flagTravels = !_.isEmpty(state.travels);
    },

    SET_LOADING(state, value) {
        state.loading = value;
    },

    MUTATION_FILTER_TRAVELS(state, filters) {
        state.selectedFilters.product_name = filters.product_name || [];
        state.selectedFilters.licence_plate = filters.licence_plate || [];
        state.selectedFilters.permittee = filters.permittee || [];
        state.selectedFilters.unit_id = filters.unit_id || [];
        state.selectedFilters.customers = filters.customers || [];
        state.travels = state.travelsTemp.filter(travel => {
            if (state.selectedFilters.product_name.length > 0 && 
                !_.some(travel.lines, line => state.selectedFilters.product_name.includes(line.product_name))) {
                return false;
            }
    
            if (state.selectedFilters.licence_plate.length > 0 && 
                !state.selectedFilters.licence_plate.includes(travel.unit.licence_plate)) {
                return false;
            }
    
            if (state.selectedFilters.permittee.length > 0 && 
                !state.selectedFilters.permittee.includes(travel.permittee.name)) {
                return false;
            }
    
            if (state.selectedFilters.unit_id.length > 0 && 
                !state.selectedFilters.unit_id.includes(travel.unit.id)) {
                return false;
            }
    
            if (state.selectedFilters.customers.length > 0 && 
                !_.some(travel.lines, line => state.selectedFilters.customers.includes(line.source_customer))) {
                return false;
            }
    
            return true;
        });
    }
    
};

const actions = {
    getTravels(context, { start_date, end_date }) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            api.get(start_date, end_date).then(res => {
                context.commit('MUTATIONS_SET_TRAVELS', res.data.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                resolve(res.data);
                context.commit('SET_LOADING', false);
            }).catch(error => {
                context.commit('SET_LOADING', false);
                reject(error);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
            });
        });
    },

    moreTravels(context, data) {
        context.commit('MUTATION_FILTER_TRAVELS', data);
    }
};

const getters = {
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
