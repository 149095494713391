let prefix = '/api/user';

function getInformation() {
    return window.axios.get(prefix+'/information');
}
function getUsers(){
    return window.axios.get(prefix+'/index');
}
function getUsersSSO(){
    return window.axios.get(prefix+'/index-sso');
}
function store(data){
    return window.axios.post(prefix+'/store', data);
}
function update(id, data){
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
//Asisgnar/Quitar roles a usuarios
function assignRolToUser(user, role){
    return window.axios.post(prefix+'/assign-role-to-user/'+user+'/'+role);
}
function retractRolFromUser(user, role){
    return window.axios.post(prefix+'/retract-role-from-user/'+user+'/'+role);
}
function allowAbilityToUser(user, ability){
    return window.axios.post(prefix+'/allow-ability-to-user/'+user+'/'+ability);
}
function disallowAbilityToUser(user, ability){
    return window.axios.post(prefix+'/disallow-ability-to-user/'+user+'/'+ability);
}
export default {
    getInformation,
    getUsers,
    getUsersSSO,
    store,
    update,
    deleted,
    //Asignar/Quitar roles de usuarios
    assignRolToUser,
    retractRolFromUser,
    allowAbilityToUser,
    disallowAbilityToUser,
    
}